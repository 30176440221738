import React from "react";
import "./skills.css";
import RepairTools from "../../assets/repair-tools.png";
import Lamp from "../../assets/lamp.png";
import SmartHouse from "../../assets/smart-house.png";
import CCTV from "../../assets/cctv.png";
import EcoHouse from "../../assets/eco-house.png";

const Skills = () => {
  return (
    <section id="skills">
      <span className="skillTitle">Unsere Dienstleistungen im Überblick:</span>
      {/* <span className="skillDescription">
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eum
        perspiciatis id tempore laborum tempora dignissimos itaque suscipit
        excepturi totam eius qui temporibus necessitatibus, debitis architecto
        perferendis officia veniam magnam incidunt?
      </span> */}
      <div className="skillBars">
        <div className="skillBar">
          <img src={EcoHouse} alt="" className="skillBarImg" />
          <div className="skillBarText">
            <h2>
              Elektro<wbr></wbr>installation
            </h2>
            <p>
              Wir planen und führen Elektroinstallationen nach höchsten
              Standards durch. Ob Neubau, Renovierung oder Erweiterung - wir
              sorgen für sichere und zuverlässige elektrische Systeme.
            </p>
          </div>
        </div>
        <div className="skillBar">
          <img src={RepairTools} alt="" className="skillBarImg" />
          <div className="skillBarText">
            <h2>Reparaturen und Wartung</h2>
            <p>
              Unser Notdienst ist rund um die Uhr für Sie erreichbar, um
              elektrische Störungen schnell und effizient zu beheben. Darüber
              hinaus bieten wir regelmäßige Wartungsarbeiten, um Ausfallzeiten
              zu minimieren.
            </p>
          </div>
        </div>
        <div className="skillBar">
          <img src={Lamp} alt="" className="skillBarImg" />
          <div className="skillBarText">
            <h2>Beleuchtung</h2>
            <p>
              Wir schaffen die perfekte Atmosphäre mit unserer professionellen
              Beleuchtungsinstallation. Ob energiesparende LED-Beleuchtung oder
              maßgeschneiderte Lösungen - Ihre Zufriedenheit steht an erster
              Stelle.
            </p>
          </div>
        </div>
        <div className="skillBar">
          <img src={CCTV} alt="" className="skillBarImg" />
          <div className="skillBarText">
            <h2>Sicherheitstechnik</h2>
            <p>
              Schützen Sie Ihr Zuhause oder Ihr Unternehmen mit unseren
              hochwertigen Sicherheitssystemen. Von Alarmanlagen bis zu
              Videoüberwachung bieten wir maßgeschneiderte Lösungen.
            </p>
          </div>
        </div>
        <div className="skillBar">
          <img src={SmartHouse} alt="" className="skillBarImg" />
          <div className="skillBarText">
            <h2>Smart Home-Lösungen</h2>
            <p>
              Nutzen Sie die Vorteile der modernen Technologie. Wir integrieren
              Smart Home-Systeme, die Ihren Alltag komfortabler und effizienter
              gestalten.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;
