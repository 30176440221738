import React from "react";
import "./intro.css";
import bg from "../../assets/1.png";
import btnImg from "../../assets/hireme.png";
import { Link } from "react-scroll";

const Intro = () => {
  return (
    <section id="intro">
      <div className="introContent">
        <span className="hello" id="text">
          Hallo,
        </span>
        <span className="introText">
          Wir sind <br />
          <span className="introName"> Grün Belen</span> <br />
          Elektrotechnik Meisterbetrieb
        </span>
        <p className="introPara">
          Ihr kompetenter Partner für Elektroinstallationen und
          -dienstleistungen!
        </p>

        {/* <Link>
          <button className="btn">
            <img src={btnImg} alt="Hire Me" className="btnImg" />
            Hire Me
          </button>
        </Link> */}
      </div>

      <img src={bg} alt="Profile" className="bg"></img>
    </section>
  );
};

export default Intro;
