import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      Copyright &#169; 2023 - Grün Belen Elektrotechnik Gbr
    </footer>
  );
};

export default Footer;
