import React from "react";
import "./works.css";

import Portfolio2 from "../../assets/2.png";
import Portfolio3 from "../../assets/3.png";
import Portfolio4 from "../../assets/4.png";
import Portfolio5 from "../../assets/5.png";
import Portfolio6 from "../../assets/6.png";
import Portfolio7 from "../../assets/7.png";
import Portfolio8 from "../../assets/8.png";
import Portfolio9 from "../../assets/9.png";
import Portfolio10 from "../../assets/10.png";
import Portfolio11 from "../../assets/11.png";

const Works = () => {
  return (
    <section id="works">
      <h2 className="worksTitle">Unser Portfolio:</h2>
      <h5 className="worksTitle2">Exzellente Elektroarbeiten</h5>
      <span className="worksDesc">
        In den Jahren unserer Tätigkeit als Elektro Meisterbetrieb haben wir
        zahlreiche Projekte erfolgreich abgeschlossen. Unsere Leidenschaft für
        Elektrotechnik und unser Engagement für höchste Qualität spiegeln sich
        in unserem Portfolio wider. <br />
        <br />
      </span>
      <div className="worksImgs">
        {/* <img src={Portfolio2} alt="" className="worksImg" />
        <img src={Portfolio3} alt="" className="worksImg" /> */}
        <img src={Portfolio2} alt="" className="worksImg" />
        <img src={Portfolio3} alt="" className="worksImg" />
        <img src={Portfolio4} alt="" className="worksImg" />
        <img src={Portfolio5} alt="" className="worksImg" />
        <img src={Portfolio6} alt="" className="worksImg" />
        <img src={Portfolio7} alt="" className="worksImg" />
        <img src={Portfolio8} alt="" className="worksImg" />
        <img src={Portfolio9} alt="" className="worksImg" />
        <img src={Portfolio10} alt="" className="worksImg" />
        <img src={Portfolio11} alt="" className="worksImg" />
      </div>
    </section>
  );
};

export default Works;
