import React from "react";
import "./contact.css";
import Walmart from "../../assets/walmart.png";
import Adobe from "../../assets/adobe.png";
import Microsoft from "../../assets/microsoft.png";
import Facebook from "../../assets/facebook.png";
import FacebookIcon from "../../assets/facebook-icon.png";
import TwitterIcon from "../../assets/twitter.png";
import YouTubeIcon from "../../assets/youtube.png";
import InstagramIcon from "../../assets/instagram.png";
import Fondaj from "../../assets/fondaj.png";
import MC from "../../assets/mc.png";
import Arz from "../../assets/arz.png";
import Frank from "../../assets/frank.png";
import contactImg from "../../assets/email.png";
import clickImg from "../../assets/click.png";

const Contact = () => {
  return (
    <section id="contactPage">
      <div id="clients">
        <h1 className="contactPageTitle">Unsere geschätzten Kunden:</h1>
        <p className="clientDesc">
          Unsere Kunden sind der Kern unseres Geschäfts, und wir schätzen das
          Vertrauen, das sie in uns setzen. Über die Jahre hinweg haben wir mit
          einer Vielzahl von Kunden zusammengearbeitet, von Privathaushalten bis
          hin zu Unternehmen unterschiedlicher Größe. <br />
          <br />
        </p>
        <div className="clientImgs">
          <a
            href="https://www.arzbau.com/"
            target="_blank"
            className="clientImg"
          >
            <img src={Arz} alt="" className="clientImg" />
          </a>
          <a
            href="https://www.microcoat.de/"
            target="_blank"
            className="clientImg"
          >
            <img src={MC} alt="" className="clientImg" />
          </a>
          <a
            href="https://www.fondaj-bau.de/"
            target="_blank"
            className="clientImg"
          >
            <img src={Fondaj} alt="" className="clientImg" />
          </a>
          <a
            href="https://www.tiefbau-frank.de/"
            target="_blank"
            className="clientImg"
          >
            <img src={Frank} alt="" className="clientImg" />
          </a>
        </div>
      </div>

      <div id="contact">
        <h1 className="contactPageTitle">Kontaktieren Sie uns per Mail!</h1>
        {/* <span className="contactDesc">
          Bitte fülle das Formular aus für eine Zusammenarbeit!
        </span> */}
        <form className="contactForm">
          {/* <input type="text" className="name" placeholder="Dein Name" />
          <input type="email" className="email" placeholder="Deine E-Mail" />
          <textarea
            className="msg"
            name="message"
            rows="5"
            placeholder="Deine Nachricht"
          ></textarea> */}

          <a className="submitBtn" href="mailto:info@g-belenelektro.de">
            <img src={contactImg} alt="" className="mailImg" />
          </a>
          <h5 className="worksTitle2">
            {" "}
            <a id="mail" href="mailto:info@g-belenelektro.de">
              info@g-belenelektro.de
            </a>
          </h5>

          {/* <div className="links">
            <img src={FacebookIcon} alt="" className="link" />
            <img src={TwitterIcon} alt="" className="link" />
            <img src={YouTubeIcon} alt="" className="link" />
            <img src={InstagramIcon} alt="" className="link" />
          </div> */}
        </form>
      </div>
    </section>
  );
};

export default Contact;

/*
<div id="contact">
        <h1 className="contactPageTitle">Kontaktiere uns!</h1>
        <span className="contactDesc">
          Bitte fülle das Formular aus für eine Zusammenarbeit!
        </span>
        <form className="contactForm">
          <input type="text" className="name" placeholder="Dein Name" />
          <input type="email" className="email" placeholder="Deine E-Mail" />
          <textarea
            className="msg"
            name="message"
            rows="5"
            placeholder="Deine Nachricht"
          ></textarea>
          <button type="submit" value="Send" className="submitBtn">
            e-Mail
          </button>
          <a href="mailto:someone@yoursite.com">Email Us</a>
          <div className="links">
            <img src={FacebookIcon} alt="" className="link" />
            <img src={TwitterIcon} alt="" className="link" />
            <img src={YouTubeIcon} alt="" className="link" />
            <img src={InstagramIcon} alt="" className="link" />
          </div>
        </form>
      </div>
*/
